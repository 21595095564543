import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import ContactUs2 from "views/LandingPage/Sections/ContactUs2.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class ContactUs extends React.Component {

  constructor(props) {
    super(props);
    props = this.props;
    //classes = this.classes;
  }
  render() {
    const { classes } = this.props;

    return (
      <ContactUs2 />
    );
  }
}

export default withStyles(workStyle)(ContactUs);
