//import './App.css';
import React, { useState } from 'react';//import { send } from 'emailjs-com';
import emailjs from '@emailjs/browser';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

//class ContactUs extends React.Component {//  render() {

//function ContactUs2() {				//	ContactUs2 = (classes) => {
export default function ContactUs2(classes) {  //const { classes } = props.classes;  //const { classes, ...rest } = this.props;  //const { classes } = this.classes;

  const [toSend, setToSend] = useState({
    from_name: '',
    from_email: '',
    to_name: 'framelevel_support',
    message: '',
    to_email: 'info@framelevel.com',
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleSend = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_b0ntyq6',
      'template_7dsk36e',
      toSend,
      'user_bABDJQjIfbzUvJAxyJlwr'
    )
      .then((response) => {
        //console.log('SUCCESS!', response.status, response.text);
        confirm('request sent...', response.status, response.text);
      })
      .catch((err) => {
        //console.log('FAILED...', err);
        confirm('request failed...', err);
      });
    <a href="#top" />     
  };
  //     send('SERVICE ID',	'TEMPLATE ID', toSend, 'User ID' )


  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <br/><br/><br/><br/>
          <h4
            className={classes.title}
            style={{
              paddingTop: 0,
              fontSize: "18px",
              color: "purple",
              fontStyle: "italic",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            Contact us...
          </h4>
          <br/>
          <h5 className={classes.description}
            style={{
              paddingTop: 0,
              fontSize: "16px",
              color: "black",
              fontStyle: "normal",
              fontWeight: "normal",
              textAlign: "center"
            }}
          >
            If you have questions, need specific information or want some
            help, send us a note that describes your situation and we'll
            respond as quickly as possible.
          </h5>
          <br/>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Name"
                  id="from_name"
                  name="from_name"
                  onChange={handleChange}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Your Email"
                  id="from_email"
                  name="from_email"
                  onChange={handleChange}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="Your Message"
                id="message"
                name="message"
                onChange={handleChange}
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.textCenter}
                >
<br/>
                <Button
                  color="danger"
                  size="lg"
                  target="_blank"
                  
                  rel="noopener noreferrer" 
                  variant="contained"
                  onClick={handleSend} >
                  connect...
                </Button>
                {/**<Button type="submit" color="primary">Send Message</Button> */}
                </GridItem>
              </GridContainer>
            </GridContainer>
            <br/>
          </form>
        </GridItem>
      </GridContainer>
      <div id="bot"></div>
    </div>
  );
}
//}

//export default withStyles(workStyle)(ContactUs);
