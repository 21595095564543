import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function editPE21() {


  const searchPE = () => {
    var lookup = {
        title: 'A post about true facts',
        elementid: '0000',
        userId: 2
    };
    var options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(lookup)
    };
    let eid = elementid.value;
    var id = "00000000".substring(0, 8-eid.length) + eid;    //var id = normalizeID(eid,IDLEN,zerostring);
    console.log("calling searchPE...");                           //const zerostring = "00000000";

    if (id!="") {
        lookup.elementid = id;
        options.body = JSON.stringify(lookup);

        //const url = "http://127.0.0.1:5000/users/login"; const response = await Axios.post(url,data,{headers:{"Content-Type" : "application/json"}});
        fetch('http://127.0.0.1:5000/peselectbyid', options)
            .then(function(response) {
                console.log("The function query-result (from response is: ", response.peid); //console.log(eid + ' - found record');        //console.log(results);
                return response.json();
            })
            .then(function(pej) {
                JSON.stringify(pej);
                console.log("The fetch query-result (from pej[0]) is: ", pej[0].elementid); //console.log(eid + ' - found record');        //console.log(results);

                //if (pej[0].dbid == "") { dbid.placeholder = ""; else dbid.value = pej[0].dbid;
                if (pej[0].elementid == "") elementid.placeholder = ""; else elementid.value = pej[0].elementid;
                if (pej[0].otherid == "") otherid.placeholder = ""; else otherid.value = pej[0].otherid; 
                if (pej[0].recorddt == "") recorddt.placeholder = ""; else recorddt.value = pej[0].recorddt; 
                if (pej[0].product == "") product.placeholder = ""; else product.value = pej[0].product;
                if (pej[0].secondtitle == "") secondtitle.placeholder = ""; else secondtitle.value = pej[0].secondtitle;
                if (pej[0].project == "") project.placeholder = ""; else project.value = pej[0].project;
                if (pej[0].format == "") format.placeholder = ""; else format.value = pej[0].format;
                if (pej[0].generation == "") generation.placeholder = ""; else generation.value = pej[0].generation;
                if (pej[0].duration == "") duration.placeholder = ""; else duration.value = pej[0].duration;
                if (pej[0].status == "") rstatus.placeholder = ""; else rstatus.value = pej[0].status;
                if (pej[0].parents == "") parents.placeholder = ""; else parents.value = pej[0].parents;
                if (pej[0].children == "") children.placeholder = ""; else children.value = pej[0].children;
                if (pej[0].vault == "") vault.placeholder = ""; else vault.value = pej[0].vault;
                if (pej[0].shelf == "") shelf.placeholder = ""; else shelf.value = pej[0].shelf;
                if (pej[0].location == "") location.placeholder = ""; else location.value = pej[0].location;
                if (pej[0].currentloc == "") currentloc.placeholder = ""; else currentloc.value = pej[0].currentloc;
                if (pej[0].reelnbr == "") reelnbr.placeholder = ""; else reelnbr.value = pej[0].reelnbr;
                if (pej[0].reelcnt == "") reelcnt.placeholder = ""; else reelcnt.value = pej[0].reelcnt;
                if (pej[0].etype == "") etype.placeholder = ""; else etype.value = pej[0].etype;
                if (pej[0].cparent == "") cparent.placeholder = ""; else cparent.value = pej[0].cparent;
                if (pej[0].title == "") title.value = " "; else title.value = pej[0].title;
            });                                
    }
  };

  {/**
                //if (pej[0].segment == "") segment.placeholder = ""; else segment.value = pej[0].segment;
                //if (pej[0].language == "") language.placeholder = ""; else language.value = pej[0].language;
                //if (pej[0].job == "") job.placeholder = ""; else job.value = pej[0].job;
                //if (pej[0].venue == "") venue.placeholder = ""; else venue.value = pej[0].venue;
                //if (pej[0].associd == "") associd.placeholder = ""; else associd.value = pej[0].associd;
                //if (pej[0].sourcearchivenbr == "") sourcearchivenbr.placeholder = ""; else sourcearchivenbr.value = pej[0].sourcearchivenbr;
                //if (pej[0].sourcebarcode == "") sourcebarcode.placeholder = ""; else sourcebarcode.value = pej[0].sourcebarcode;
                //if (pej[0].archivedt == "") archivedt.placeholder = ""; else archivedt.value = pej[0].archivedt;
                //if (pej[0].techcmnts == "") techcmnts.placeholder = ""; else techcmnts.value = pej[0].techcmnts;
                //if (pej[0].contcmnts == "") contcmnts.placeholder = ""; else contcmnts.value = pej[0].contcmnts;
 */}

  return (
    <React.Fragment>
      {/*<Typography variant="h6" gutterBottom> ID </Typography> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <Typography variant="h5" gutterTop>   </Typography>
        </Grid>  
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="elementid" name="elementid" label="elementid" required fullWidth autoComplete="elementid" onBlur={searchPE} />   {/* onfocusout={searchPE} */}
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="otherid" name="otherid" label="altID" fullWidth autoComplete="otherid" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="recorddt" name="recorddt" label="recDT" fullWidth autoComplete="recorddt" />
        </Grid>
        <Grid item xs={12} sm={1}>
          <TextField InputLabelProps={{ shrink: true }}  id="rstatus" name="rstatus" label="rstatus" fullWidth autoComplete="rstatus" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="product" name="product" label="product" fullWidth autoComplete="product" />
        </Grid>
        <Grid item xs={10}>
          <TextField InputLabelProps={{ shrink: true }}  id="title" name="title" label="Show Title" fullWidth autoComplete="shipping address-line1" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }}  id="project" name="project" label="project" fullWidth autoComplete="project" />
        </Grid>
        <Grid item xs={10}>
          <TextField InputLabelProps={{ shrink: true }}  id="secondtitle" name="secondtitle" label="Episode Title" fullWidth autoComplete="secondtitle" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }}  id="format" name="format" label="format" fullWidth autoComplete="format" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }}  id="generation" name="generation" label="gen" fullWidth autoComplete="generation"/>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="duration" name="duration" label="dur" fullWidth autoComplete="duration" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="reelnbr" name="reelnbr" label="reel" fullWidth autoComplete="reelnbr" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="reelcnt" name="reelcnt" label="reel count" fullWidth autoComplete="reelcnt" />
        </Grid>
        <Grid item xs={12} sm={2}> 
          <TextField InputLabelProps={{ shrink: true }}  id="etype" name="etype" label="etype" fullWidth autoComplete="etype" />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="vault" name="vault" label="vault" fullWidth autoComplete="vault" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="shelf" name="shelf" label="shelf" fullWidth autoComplete="shelf" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="location" name="location" label="location" fullWidth autoComplete="location" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="currentloc" name="currentloc" label="currentloc" fullWidth autoComplete="currentloc" />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="cparent" name="cparent" label="container parent" fullWidth autoComplete="cparent" />
        </Grid>
        <Grid item xs={10}>
          <TextField InputLabelProps={{ shrink: true }}  
          id="parents" name="parents" label="content parents" fullWidth autoComplete="parents" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField InputLabelProps={{ shrink: true }} 
            id="job" name="job" label="job" fullWidth autoComplete="job" />
        </Grid>
        <Grid item xs={10}>
          <TextField InputLabelProps={{ shrink: true }}  
          id="children" name="children" label="content children" fullWidth autoComplete="children" />
        </Grid>
        {/*<Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid>* */}
      </Grid>
    </React.Fragment>
  );
}