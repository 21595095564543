import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DoneAll from "@material-ui/icons/DoneAll";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class DataMapSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "bold",
            marginLeft: "40px",
            textAlign: "left"
          }}
        >
          DATAMAP - DATA CONVERSION
        </h4>
        <p
          style={{
            fontSize: "16px",
            color: "#000000",
            fontStyle: "bold",
            marginLeft: "80px",
            marginRight: "80px",
            textAlign: "left"
          }}
        >
          Almost every content library and media owner has some kind of
          operating data catalog that helps run the business. These catalogs
          vary in size and complexity and can often hinder efforts to get the
          asset library organized. It might simply be too much work to move all
          of that data from the old system to the new one and then verify the
          process. Legitimate excuses often overwhelm better ideas of improving
          operations by adapting a more responsive data model, and in the end
          the project never gets done. Our Datamap data conversion tool migrates
          your data from the old to the new system, generates new index keys,
          and retains all of the references to the previous database so that we
          can easily retrace our steps for any reason in the future.
          <br />
        </p>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            <DoneAll
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px"
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left"
              }}
            >
              Data Mapping
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left"
              }}
            >
              We also offer a detailed approach to data conversion that maps
              your old catalog to the new data store one record at a time,
              moving the old record, then mapping it to the new data structure
              and displaying the information on screen, ready for review from
              one of our experienced librarians. It gets reviewed and corrected,
              and new information is added per your instructions - anything from
              detail frame level content, synopsis and storyline, clip and scene
              descriptions, or technical detail. When the update is complete,
              the librarian saves the new record, prints barcodes (for physical
              media), repackages the item, places the labels, and moves it to
              the finished-rack for shelving. Believe me, this
              one-record-at-a-time approach generates a whole new universe of
              useful product, operations and business information.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(DataMapSection);
