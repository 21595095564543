import React from 'react'
// import PhysicalelementForm from './PhysicalelementForm.jsx'
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import logo from "../../assets/img/framelevellogo65d4s1.png"; // relative path to image

export default function PhysicalelementsList() {
    return (
        <div>
            <Header
              absolute
              color="transparent"
              brand=""
              leftLinks={<img src={logo} alt={logo} />}
              rightLinks={<HeaderLinks />}
            />
            {/**<PhysicalelementForm /> */}
        </div>
    )
}


//const PhysicalelementsListContainer = connect((dispatch) => ({ dispatch }))(PhysicalelementsList);

//export default withStyles(PhysicalelementsListStyle)(PhysicalelementsListContainer);

