import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Done from "@material-ui/icons/Done";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const itemicon = {
  color: "primary",
  fontSize: "20",
  marginLeft: "194px"
};

const itemhdr = {
  fontSize: "18px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "20px",
  marginRight: "120px",
  textAlign: "left"
};

const itembody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

const itemlinehdr = {
  fontSize: "16px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "normal",
  marginLeft: "40px",
  marginRight: "140px",
  textAlign: "left"
};

const itemlinebody = {
  fontSize: "13.5px",
  color: "#000000",
  fontStyle: "normal",
  fontWeight: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

class ComponentSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "24px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "extrabold",
            textAlign: "center"
          }}
        >
          COMPONENTS
        </h4>

        <p
          style={{
            fontSize: "16px",
            color: "#000000",
            fontStyle: "bold",
            marginLeft: "100px",
            marginRight: "80px",
            textAlign: "left"
          }}
        >
          We organize our business model around our software. Over time we have
          developed a number of independent elements that fit our products well,
          allowing them to share information effectively. We call these elements
          components, and we offer a brief functional description of them here,
          so you can better understand how they work.
        </p>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Library Services</p>
            <p style={itembody}>
              Well engineered library services form the backbone of of any
              effective content management system. Users need to know what
              material is available and how to get it. We do both with ProStor,
              our industry tested, easy-to-use library services platform.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}> Collect all of your metadata </p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Leverage our custom tag dictionaries</p>
            <p style={itemlinebody}>
              We deliver tagging tools that let you define content exactly the
              way you want it. Better yet, we let you define your own
              heirarchical tag dictionaries so you can leverage our boolean
              tools to precisely identify every item within your entire media
              catalog.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage elements across container boundries
            </p>
            <p style={itemlinebody}>
              Lots media is stored along with a collection of items in a zip
              file on a tape with many other zip files, and maybe that tape is
              stored in a box with lots of other tapes (which may be stored in
              an even larger box). We call these collections containers, and we
              manage containers correctly, so that you can easily identify
              anything in your library quickly, and then retrieve exactly what
              you want.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Find, compare, reserve and utilize exactly what you need
            </p>
            <p style={itemlinebody}>
              Let our single resource find any content in your library, place
              the necessary requests, then track and deliver your material from
              wherever it is directly to your desktop.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Easy to learn, easy to use</p>
            <p style={itemlinebody}>
              Our user interface is well thought out, seasoned and tested over
              time. We're focused on helping you complete those repetetive tasks
              quickly and intuitively with a professional touch.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Tools and services to stretch your world</p>
            <p style={itemlinebody}>
              Delivering connectivity is a must in today's digital world. We
              hook the most popular online products, like Slack for messaging,
              and Monday for project review, helping you stretch your world and
              discover new horizons for creative development.
            </p>
            <br />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Physical Media Management</p>
            <p style={itemlinebody}>
              We organize just those digital assets you choose into a well
              designed, integrated and responsive digital library management
              system. When we're done, your material is tagged and identified in
              ways that make it easy to find and easy to use, helping you run
              your operation effectively, keeping your business model in mind.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Leverage our custom tag dictionaries</p>
            <p style={itemlinebody}>
              We deliver tagging tools that let you define content exactly the
              way you want it. Better yet, we let you define your own
              heirarchical tag dictionaries so you can leverage our boolean
              tools to precisely identify every item within your entire media
              catalog.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage elements across container boundries
            </p>
            <p style={itemlinebody}>
              Lots media is stored along with a collection of items in a zip
              file on a tape with many other zip files, and maybe that tape is
              stored in a box with lots of other tapes (which may be stored in
              an even larger box). We call these collections containers, and we
              manage containers correctly, so that you can easily identify
              anything in your library quickly, and then retrieve exactly what
              you want.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Find, compare, reserve and utilize exactly what you need
            </p>
            <p style={itemlinebody}>
              Let our single resource find any content in your library, place
              the necessary requests, then track and deliver your material from
              wherever it is directly to your desktop.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Easy to learn, easy to use</p>
            <p style={itemlinebody}>
              Our user interface is well thought out, seasoned and tested over
              time. We're focused on helping you complete those repetetive tasks
              quickly and intuitively with a professional touch.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Tools and services to stretch your world</p>
            <p style={itemlinebody}>
              elivering connectivity is a must in today's digital world. We hook
              the most popular online products, like Slack for messaging, and
              Monday for project review, helping you stretch your world and
              discover new horizons for creative development.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Digital Media Management</p>
            <p style={itemlinebody}>
              We organize just those digital assets you choose into a well
              designed, integrated and responsive digital library management
              system. When we're done, your material is tagged and identified in
              ways that make it easy to find and easy to use, helping you run
              your operation effectively, keeping your business model in mind.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Leverage our custom tag dictionaries</p>
            <p style={itemlinebody}>
              We deliver tagging tools that let you define content exactly the
              way you want it. Better yet, we let you define your own
              heirarchical tag dictionaries so you can leverage our boolean
              tools to precisely identify every item within your entire media
              catalog.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage elements across container boundries
            </p>
            <p style={itemlinebody}>
              Lots media is stored along with a collection of items in a zip
              file on a tape with many other zip files, and maybe that tape is
              stored in a box with lots of other tapes (which may be stored in
              an even larger box). We call these collections containers, and we
              manage containers correctly, so that you can easily identify
              anything in your library quickly, and then retrieve exactly what
              you want.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Find, compare, reserve and utilize exactly what you need
            </p>
            <p style={itemlinebody}>
              Let our single resource find any content in your library, place
              the necessary requests, then track and deliver your material from
              wherever it is directly to your desktop.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Easy to learn, easy to use</p>
            <p style={itemlinebody}>
              Our user interface is well thought out, seasoned and tested over
              time. We're focused on helping you complete those repetetive tasks
              quickly and intuitively with a professional touch.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Tools and services to stretch your world</p>
            <p style={itemlinebody}>
              elivering connectivity is a must in today's digital world. We hook
              the most popular online products, like Slack for messaging, and
              Monday for project review, helping you stretch your world and
              discover new horizons for creative development.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Archive Creation & Control</p>
            <p style={itembody}>
              Well engineered library services form the backbone of of any
              effective content management system. Users need to know what
              material is available and how to get it. We do both with ProStor,
              our industry tested, easy-to-use library services platform.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <FlightTakeoff color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Annotation and Tagging</p>
            <p style={itemlinebody}>
              From the Internet to cell phones to cable TV, consumers are hungry
              for on-demand content. New media is being created at a torrid pace
              in order to fill that demand, and existing material has become
              more valuable than ever. To capitalize in today's marketplace you
              need to take aggressive steps to organize your library around
              quality content. At framelevel, we provide the expertise to help
              you organize your assets and software to run your operation. When
              we're done, your material is tagged and identified in ways that
              make it easy to find – housed in a well designed, integrated and
              responsive library management system.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>
              Cataloging, Identification, Barcoding, Repackaging, Reshelving,
              Annotation and Tagging
            </p>
            <p style={itemlinebody}>
              From the Internet to cell phones to cable TV, consumers are hungry
              for on-demand content. New media is being created at a torrid pace
              in order to fill that demand, and existing material has become
              more valuable than ever. To capitalize in today's marketplace you
              need to take aggressive steps to organize your library around
              quality content. At framelevel, we provide the expertise to help
              you organize your assets and software to run your operation. When
              we're done, your material is tagged and identified in ways that
              make it easy to find – housed in a well designed, integrated and
              responsive library management system.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Data Mapping</p>
            <p style={itemlinebody}>
              WFrom the Internet to cell phones to cable TV, consumers are
              hungry for on-demand content. New media is being created at a
              torrid pace in order to fill that demand, and existing material
              has become more valuable than ever. To capitalize in today's
              marketplace you need to take aggressive steps to organize your
              library around quality content. At framelevel, we provide the
              expertise to help you organize your assets and software to run
              your operation. When we're done, your material is tagged and
              identified in ways that make it easy to find – housed in a well
              designed, integrated and responsive library management system.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Manage your entire collection from one place
            </p>
            <p style={itemlinebody}>
              We organize all of your physical, digital and archival assets,
              delivering the software to run your operation, providing the
              services you need to keep your library in top shape and up to
              date.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Collect all of your metadata</p>
            <p style={itemlinebody}>
              Our data models are extensive, developed over years of dealing
              with large media-centric data collections. Use our experience to
              better identify your media content.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>
              Add every note you need exactly where you want it
            </p>
            <p style={itemlinebody}>
              We provide space for endless notes and tags, letting you correctly
              identify everything from technical information to content
              descriptions, all in the content stream in exactly the right
              place. Better still, everything you add is immediately searchable,
              available for recall and review across your entire library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinehdr}>Supercharge your searches</p>
            <p style={itemlinebody}>
              Customize your queries to search every corner of your catalog,
              stack them to find exactly what you're looking for, then save them
              for reuse whenever the need arises.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(ComponentSection);
