import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
//import Done from "@material-ui/icons/Done";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const itemicon = {
  color: "primary",
  fontSize: "20",
  marginLeft: "220px"
};

const itemhdr = {
  fontSize: "18px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "20px",
  marginRight: "120px",
  textAlign: "left"
};

const itembody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

class MarketsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center"
              }}
            >
              We develop market specific versions for each of our great
              products...
            </h4>
            <h5
              className={classes.description}
              style={{
                paddingTop: 0,
                marginTop: -30
              }}
            >
              Production operations can inhibit your business by dictating how
              the process works, often yielding disappointing
              results.&nbsp;&nbsp;FrameLevel takes a different approach: drawing
              on our experience to carefully craft applications that increase
              productivity, match your business needs, and improve bottom line
              results.&nbsp;&nbsp;From specifications to design to custom
              development, we can deliver the solution you need when you&apos;re
              ready to get started.
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Media Production</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Media recording tools have improved dramatically, resulting in
              confusion for everyone from asset managers to content creators. At
              FrameLevel, we accurately identify content, manage both physical
              AND digital archives, connect disparate databases, and help you
              deliver the best possible product.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Sports Analytics</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              In today&apos;s hyper-connected world, sport has become huge
              business where success depends on winning and winning demands
              performance. At FrameLevel we create winners by collecting
              sophisticated metrics, revealing precise performance analytics for
              athletes, trends and tendencies for teams, and historical insite
              into the competition.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Surveillance Footage</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              The world today is a dangerous place. Surveillance video has
              become a necessary fact of life for many modern businesses in
              order to protect against every possibility from theft to legal
              issues. As these systems have become mode sophisticated and
              responsive, storage requirements have exploded - forget about
              trying to find anything once you&apos;ve recorded it. Our
              extensive experience in content management puts us in a perfect
              place to apply the technologies we&apos;ve developed in order to
              respond to these market requirements in a sophisticated way.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Medical Facilities</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              The practice of medicine is changing at alarming speed, and
              medical professionals are understandably overwhelmed by the
              complexities. At FrameLevel, we have developed exceptional media
              tagging and data mining tools, combined them with the best social
              platform, and now deliver the result to the medical community for
              collaboration and research.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Advertising Metrics</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              The marketing and advertising worlds have been turned upside down
              with the advent of the internet, and innovative new ways to gauge
              market success are being invented now. At FrameLevel we have
              teamed with cutting edge psychiatric professionals to gauge
              participant response and rate campaigns over the web, collecting
              big data quickly and delivering analytics that cut to the essence
              for our clients.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Scientific Research</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Scientific research often produces confusing, complicated and
              overwhelming results. Analyzing the data can be difficult, often
              riddled with problems maintaining accuracy and interpreting
              results. Our client-driven tag dictionaries let researchers define
              the response model and enlighten the process, allowing them to
              more effectively manage of all their underlying research data.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Academic Libraries</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <KeyboardArrowRight color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Academics have long been disadvantaged by the volume of research
              material (including media) and the effort it takes to locate
              reference specifics. Now at FrameLevel we offer collaborative
              tools that reach out to the community, combining them with
              granular reference tools that allow academics to build extensive
              cross-tabular reference lists and communicate their research with
              other interested professionals.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(MarketsSection);
