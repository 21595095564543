import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
//import group_work from "@material-ui/icons/GroupWork";
//import Subject from "@material-ui/icons/Subject";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";
//import { FormatItalic } from "@material-ui/icons";

class FeatureList extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        {/* <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          by providing...
        </h4> */}

        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            {/* <h4 className={classes.title}>We include...</h4>color: "#00fd36", 
            <h5 className={classes.description}>*/}
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              by leveraging our core products... <br />
              <br /> from professional collections management to library
              services using Prostor
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              Catalog all of your media (physical, digital and archival) in one
              place
              <br />
              using our comprehensive library management services,
              <br />
              and a simple, familiar, easy-to-learn UI.
              <br />
              We make it easy to track, search, compare, reserve, and recall
              anything in your library.
              <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              archive organization, management and control with BackPac
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              We add the latest LTO/LTFS tools to your existing archive,
              <br />
              index everything in detail,
              <br />
              then add scheduling, extensive search and discrete recall
              <br />
              to deliver content from across your entire library.
              <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              expert cataloging, unlimited annotation and custom tagging with
              EZCat
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              Catalog, annotate and tag media to your hearts content,
              <br />
              collaborate anywhere with anyone,
              <br />
              search everything (physical, digital and archival) from one place,
              <br />
              then leverage our on-site, local or cloud micro-vaults for
              security, access, and peace of mind.
              <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              <br />
              <br />
              and extending your reach with our professional services... <br />
              <br />
              database design
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              Catalog all of your media (physical, digital and archival) in one
              place
              <br />
              with our comprehensive library management services,
              <br />
              and a simple, familiar, easy-to-learn UI.
              <br />
              We make it easy to track, search, compare, reserve, and recall
              anything in your library.
              <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              programming
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              We add LTO/LTFS tools to your existing archive, <br />
              index everything in detail, <br />
              then add scheduling, extensive search and discrete recall <br />
              to deliver content from across your entire library. <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              data cataloging and annotation
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              Catalog, annotate and tag media to your hearts content,
              <br />
              collaborate anywhere with anyone,
              <br />
              search everything (physical, digital and archival) from one place,
              <br />
              then leverage our on-site, local or cloud micro-vaults for
              security, access, and peace of mind.
              <br />
            </p>
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "left"
              }}
            >
              barcoding and repackaging
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "20px",
                textAlign: "left"
              }}
            >
              Catalog, annotate and tag media to your hearts content, <br />
              collaborate anywhere with anyone, <br />
              search everything (physical, digital and archival) from one place,{" "}
              <br />
              then leverage our on-site, local or cloud micro-vaults for
              security, access, and peace of mind. <br />
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(FeatureList);
