import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import logo from "../../assets/img/framelevellogo65d4s1.png"; // relative path to image

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import ProStorSection from "./Sections/ProStorSection.jsx";
import BackPacSection from "./Sections/BackPacSection.jsx";
import EZCatSection from "./Sections/EZCatSection.jsx";
import ReloadSection from "./Sections/ReloadSection.jsx";
import DataMapSection from "./Sections/DataMapSection.jsx";
import ComponentSection from "./Sections/ComponentSection.jsx";
//import ContactUs from "./Sections/ContactUs.jsx";
import ContactUs from "views/LandingPage/Sections/ContactUs";

const dashboardRoutes = [];

class ProductsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand=""
          leftLinks={<img src={logo} alt={logo} ref={"/landing-page"} />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />        
        <Parallax filter image={require("assets/img/mway_trimmed_fromesa.jpg")}>        {/* <Parallax filter image={require("assets/img/bg2.jpg")}>  */}
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3 className={classes.title}>Core Products and Components</h3>
                <h4>Count on Our Experience Building Asset Management Products 1</h4>
                <br />
              </GridItem>
              <br />
              <Button
                color="danger"
                size="lg"
                href="#bot"
                 
                rel="noopener noreferrer"
              >                {/* href="https://appseed.us/fullstack-apps-generator"  ref={"./sections/contactus"}  */}
                Learn More                {/*<i className="fas fa-rocket" /> Learn More  */}
              </Button>
            </GridContainer>
          </div>
        </Parallax>
          {/*target="_blank"* */}
          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ProStorSection />
            <BackPacSection />
            <EZCatSection />
            <ReloadSection />
            <DataMapSection />
            <ComponentSection />
            <ContactUs />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(ProductsPage);
