import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import client1 from "assets/img/clients/DODg.png";
import client2 from "assets/img/clients/cbs2g.jpg";
import client3 from "assets/img/clients/mgmg.jpg";
import client4 from "assets/img/clients/turner2g.jpg";
import client5 from "assets/img/clients/castlerockg.png";
import client6 from "assets/img/clients/upn2g.jpg";
import client7 from "assets/img/clients/variety2g.jpg";
import client8 from "assets/img/clients/wbg.jpg";
import client9 from "assets/img/clients/nscg.jpg";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        {/* <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",sS
            fontWeight: "normal",
            textAlign: "center"
          }}
        >
          We're proud of clients like...
        </h4> */}{/*spacing={32}   paddingright={45} paddingright={45}*/}
        <div>
          <GridContainer spacing={2}>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain></Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client1} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client2} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client3} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client4} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client5} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client6} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client7} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client8} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Card plain>
                <img src={client9} alt="..." className={imageClasses} />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);
