import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";

//import ReactDOM from "react-dom";
//import { Router, Route, Switch } from "react-router-dom";
//import { createBrowserHistory } from "history";
//import Header from "./components/layout/Header";
//import { Provider } from "react-redux";
//import store from "./store";
//import jwtDecode from "jwt-decode";//import { loginsqlite } from "./store/actions";//import { login } from "./store/actions";//import Cookies from "./utils/Cookies";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import LandingPage from "views/LandingPage/LandingPage.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import SignupPage from "views/SignupPage/SignupPage.jsx";
import ProductsPage from "views/ProductsPage/ProductsPage.jsx";
import ServicesPage from "views/ServicesPage/ServicesPage.jsx";
import SolutionsPage from "views/SolutionsPage/SolutionsPage.jsx";
import MarketsPage from "views/MarketsPage/MarketsPage.jsx";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
import ContactUs from "views/LandingPage/Sections/ContactUs.jsx";
import Dashboard from "views/Dashboard/Dashboard.js";
//import Dashboard from "views/dashboards/analytics/index.js";
import EditElement from "views/EditElements/PhysicalelementsList.jsx";
import UserContext from "context/UserContext";

//import "./style.css";
//var hist = createBrowserHistory();

export default function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });

  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await Axios.post(
        "http://localhost:5000/users/tokenIsValid",
        null,
        { headers: { "x-auth-token": token } }
      );
      if (tokenRes.data) {
        const userRes = await Axios.get("http://localhost:5000/users/", {
          headers: { "x-auth-token": token },
        });
        setUserData({
          token,
          user: userRes.data,
        });
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <>
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <div className="container">
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/services-page" component={ServicesPage} />
              <Route path="/products-page" component={ProductsPage} />
              <Route path="/landing-page" component={LandingPage} />
              <Route path="/profile-page" component={ProfilePage} />
              <Route path="/login-page" component={LoginPage} />
              <Route path="/signup-page" component={SignupPage} />
              <Route path="/solutions-page" component={SolutionsPage} />
              <Route path="/markets-page" component={MarketsPage} />
              <Route path="/aboutus-page" component={AboutUsPage} />
              <Route path="/contactus-page" component={ContactUs} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/editelement" component={EditElement} />
            </Switch>
          </div>
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}