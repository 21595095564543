import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Done from "@material-ui/icons/Done";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const itemicon = {
  color: "primary",
  fontSize: "20",
  marginLeft: "182px"
};

const itemhdr = {
  fontSize: "18px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "20px",
  marginRight: "120px",
  textAlign: "left"
};

const itembody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

class ServicesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center"
              }}
            >
              Our products are supported with services that fit your goals...
            </h4>
            <h5
              className={classes.description}
              style={{
                paddingTop: 0,
                marginTop: -30
              }}
            >
              Intelligently designed production processes can liberate ingenuity
              and greatly improve your bottom line.&nbsp;&nbsp;At FrameLevel we
              use our knowledge and experience to carefully craft tools that
              increase productivity, fit your business and improve
              results.&nbsp;&nbsp;From specifications to design to custom
              development, we can deliver the solution you need at an affordable
              price.
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Data Design</p>
            <p style={itembody}>
              Business databases usually evolve over time, leaving lots of
              detailed, poorly organized, difficult to manage information spread
              across any number of platforms. We analyze your content
              thoroughly, delivering solutions that reinvent your old data,
              extend business processes and improve your bottom line.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Software Development</p>
            <p style={itembody}>
              We build real-world production software for the business
              community. In our broad experience we&apos;ve developed solutions
              to help any number of businesses shoulder complex requirements,
              face heavy production workloads, and rely on complex backend
              databases to insure success. We look forward to helping you
              implement a better way to manage and control your future.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Barcoding & Repackaging</p>
            <p style={itembody}>
              If your media collection includes older physical media (tapes,
              cassettes, film, etc.), newsflash - those assets are valuable. You
              need to identify and catalog them to fully define and use your
              libary. We&apos;ll catalog, barcode, re-package and reshelve old
              media to properly identify, organize and add it to your library
              vault. When we&apos;re done, all of your older assets can be
              located, reserved, retrieved and utilized with our simple library
              services - ready to recall and include in your latest projects.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Cataloging</p>
            <p style={itembody}>
              Accurate info about your media helps you know what you own, where
              it is, and when to use it. Collecting that important metadata is
              often the last task in a product&apos;s life cycle, and
              unfortunately the results are often disappointing. We provide
              librarians to review, catalog, and annotate your media, providing
              a well constructed, carefully crafted data store that can be
              queried in great detail, revealing all sorts of vital information.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Annotation</p>
            <p style={itembody}>
              Many disciplines require complex content analysis to evaluate
              their subject matter. Medical research and academic studies come
              to mind. FrameLevel offers carefully constructed, easy-to-use,
              fully indexed, no-limit annotation tools that can be searched,
              referenced and leveraged with the most complex analytics.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Custom Tag Dictionaries</p>
            <p style={itembody}>
              Our elegant tagging tools offer a powerful way to identify complex
              content in concise detail. We let users build custom heirarchical
              dictionaries fit specifically to their requirements. When used
              properly, these tags can provide transparency and improve recall,
              enhancing understanding, supporting analytics and extending
              content use.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Process Tools</p>
            <p style={itembody}>
              If you are like most facilities, your operational process can
              probably stand improvement. We write custom software to fit your
              needs, improving workflow and connecting with existing procedures.
              Properly designed, custom tools can make a huge difference in
              productivity. Ask us to help you take a look at your situation.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Archive Design</p>
            <p style={itembody}>
              Archives often evolve over time in a variety of formats, located
              in different places, unavailable to users who can&apos;t find what
              they need. We can normalize your existing archives, or better yet
              provide the tools to build a fully-indexed, fully cataloged
              micro-vault, yielding petabytes of storage at reasonable prices
              using the latest technologies. In addition to all that, we also
              support off-site storage in our close-by, fully-compatible,
              easy-access micro-vaults, as well as comprehensive support for all
              of the most popular cloud alternatives.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Backup, Archival and Recovery Services</p>
            <p style={itembody}>
              Backup and archival services are core offering in our library
              services products (ProStor and BackPac). We deliver standardized
              repeatable, scheduled, project based functions that fully
              integrate with our other library services, allowing users to
              search, request and recall any item from any available resource
              throughout the library.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Offsite Storage</p>
            <p style={itembody}>
              We use the latest technologies to provide fully indexed physical
              archival storage as a core component of our data storage package.
              We include the same services for offsite storage at any of our
              local, easy-to-access physical vaults. We support all of the same
              complex indexing, detail retrieval services, and cost-effective
              storage options that we offer with our complete on-site packages.
              In addition, our search-and-retrieve library services work across
              your on-site, off-site, and cloud storage archives, delivering
              comprehensive search results and initiating library services no
              matter where your data is stored, giving you the option to choose
              the cost-effective solution that meets your business needs.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Collections Management</p>
            <p style={itembody}>
              Collection identification and management is often at the heart of
              problems that leverage media library owners have in identifying
              and utilizing what they know to be a wealth of content. We provide
              analysis, design and development of complementary software tools
              for the purpose of improving and extending media services for
              existing commercial and private media libraries. Our tools can fit
              in, providing a better solution to any existing media production
              problem.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Cloud Services</p>
            <p style={itembody}>
              We&apos;re happy if a client&apos;s database needs can be met with
              a single, locally-based network solution, since that keeps
              development requirements simple and minimizes cost. Often,
              however, clients have a number of related databases in use at
              different locations, hoping to consolidate resources while moving
              to new technology. This situation provides an opportunity to
              refactor the repository, and allows us to clean up old problems
              while implementing new solutions.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Product Distribution</p>
            <p style={itembody}>
              Getting product to market and connecting with customers is the
              most fundamental business requirement, yet in today&apos;s world
              of rapidly changing technology, it&apos;s often the most likely
              issue to be inadequately addressed. We focus on effectively
              delivering content to customers, offering solutions that
              facilitate the process, enhancing your business prospects and
              improving your bottom line.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(ServicesSection);
