import React from "react";      //  , { useState }
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tasks from "components/Tasks/Tasks.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardActions from '@material-ui/core/CardActions';
import Button from "components/CustomButtons/Button.jsx";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Draggable from 'react-draggable';
import { ResizableBox } from "react-resizable";

//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
//import { withStyles } from "@material-ui/core/styles";
//import TextField from "@material-ui/core/TextField";

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import { CssBaseline, DialogTitle } from "@material-ui/core";
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import EditPE21 from '../EditElements/EditPE21';
import EditPE22 from '../EditElements/EditPE22';
import EditPE23 from '../EditElements/EditPE23';


//import Grid from '@material-ui/core/Grid';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import logo from "assets/img/framelevellogo65d4s1.png"; 


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(64),
      height: theme.spacing(16)
    }
  },  
  resizable: {
    position: "relative",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 20,
      height: 20,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      "background-position": "bottom right",
      padding: "0 3px 3px 0",
      "background-repeat": "no-repeat",
      "background-origin": "content-box",
      "box-sizing": "border-box",
      cursor: "se-resize"
    }
  },
  //    height : '600px',    width: '830px',
  //      marginBottom: theme.spacing(3),
  //    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
  //      marginTop: theme.spacing(6),
  //      marginBottom: theme.spacing(6),
  //      padding: theme.spacing(3),
  //    },
paper: {
    elevation: 3,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  //  padding: theme.spacing(3, 0, 5),
stepper: {
    padding: theme.spacing(2, 0, 2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  header: {
    maxHeight: '10px',
    color: 'transparent',
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    padding: 0
  },
}));

/*
  dialogPaper: {
    height : '200px'
  },
  layout: {
    maxWidth: 'md',
    height : '500px',
    width: '420px',
    minwidth: '300px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
 */
const dashboardRoutes = [];
  
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        framelevel
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Detail', 'Notes', 'History', 'CheckOut', 'Return'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <EditPE21 />;
    case 1:
      return <EditPE22 />;
    case 2:
      return <EditPE23 />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Dashboard() {

  const classes = useStyles();
  //const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LocalOffer />
              </CardIcon>
              <p className={classes.cardCategory}>
                Asset Management
              </p>
              <CardActions>
                      <Button
                        text="EditPE"
                        href=""
                        onClick={handleClickOpen} >
                        Edit Media Record
                      </Button>
                      {/*  Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Open dialog
                      </Button>* 
                      onClick = { () => setOpenPopup(true) }  */}
              </CardActions>              
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Recent
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue Trends</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Librarian
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Code />
              </CardIcon>
              <p className={classes.cardCategory}>Production Status</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                  ToDo's
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Active Users OnLine</p>
              <h3 className={classes.cardTitle}>+245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Slack
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                ),
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                ),
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                ),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
              <p className={classes.cardCategoryWhite}>
                New employees on 15th September, 2016
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Name", "Salary", "Country"]}
                tableData={[
                  ["1", "Dakota Rice", "$36,738", "Niger"],
                  ["2", "Minerva Hooper", "$23,789", "Curaçao"],
                  ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
                  ["4", "Philip Chaney", "$38,735", "Korea, South"],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>             {/**<Popup  openPopup={openPopup} setOpenPopup={setOpenPopup} > <PhysicalelementForm /> </Popup> */}

      <DraggableDialog />
      <CssBaseline />
    </div>
  );
  
    function PaperComponent(props) {
      return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      );
    }
    
    function DraggableDialog() {
      const [activeStep, setActiveStep] = React.useState(0);

      const handleNext = () => {
        setActiveStep(activeStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep(activeStep - 1);
      };

      //  function myConfirmation() {
      //      return 'Are you sure you want to quit?';
      //  }
      //  window.onbeforeunload = myConfirmation;  
      // another way...
      //  onClick="return confirm('Sure you want to exit..?')"


      return (
        <div>
            <Dialog
              open={open}
              onClose={handleClose}
              PaperComponent={PaperComponent}
              maxWidth={false}
              aria-labelledby="draggable-dialog-title"
            >
              <ResizableBox
                height={780}
                width={940}
                className={classes.resizable}    //  className={this.props.classes.resizable}
              >
                <main >
                  <Paper className={classes.paper}>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                      <Header className={classes.header}
                        color="transparent"
                        routes={dashboardRoutes}
                        brand=""
                        leftLinks={<img src={logo} alt={logo} />}
                        rightLinks={<HeaderLinks />}
                        fixed
                        changeColorOnScroll={{
                          height: 100,
                          color: "white"
                        }}
                      />
                      <Typography component="h6" variant="h6" align="left">
                        Library Manager : Physical Media 
                      </Typography>
                      <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </DialogTitle>
                    <React.Fragment>
                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <Typography variant="h5" gutterBottom>
                            Thank you for your order.
                          </Typography>
                          <Typography variant="subtitle1">
                            Your order number is #2001539. We have emailed your order confirmation, and will
                            send you an update when your order has shipped.
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {getStepContent(activeStep)}
                          <div className={classes.buttons} />
                          <DialogActions>
                                {activeStep !== 0 && (
                                  <Button autoFocus onClick={handleBack} color="primary">
                                    Back
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}
                                >
                                  {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                                </Button>
                          </DialogActions>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </Paper>
                  <Copyright />
                </main>
              </ResizableBox>
            </Dialog>
        </div>
      );
    }    
};

    {/**
    <ResizableBox
      height={840}
      width={860}
      className={classes.resizable}    //  className={this.props.classes.resizable}
    >
   */}

/**
                <CssBaseline />
 *  className={classes.header}  height='10'
 * changeColorOnScroll={{  height: 100,  color: "white"   }}
                <div>
                  <Header
                    color='transparent'
                    routes={dashboardRoutes}
                    brand=""
                    leftLinks={<img src={logo} alt={logo} />}
                    rightLinks={<HeaderLinks />}
                  />  
                </div>
*/


/*
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Subscribe
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address here. We will send updates
                occasionally.
              </DialogContentText>
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                />
            </DialogContent>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleClose} color="primary">
                Subscribe
              </Button>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                  </Button>
                </div>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleClose} color="primary">
                Subscribe
              </Button>
            </DialogActions>
// import Icon from "@material-ui/core/Icon";
//import Warning from "@material-ui/icons/Warning";
//import Danger from "components/Typography/Danger.js";
//import PhysicalelementForm from "views/EditElements/PhysicalelementForm.jsx";
//import Checkout from "views/EditElements/Checkout.js";
//import styles from "assets/jss/material-kit-react/views/dashboardStyle.js";
//import DraggableDialog from "components/DragableDialog";
//import Stepper from '@material-ui/core/Stepper';
//import Step from '@material-ui/core/Step';
//import StepLabel from '@material-ui/core/StepLabel';
//import DraggableResizableDialog from '../EditElements/DraggableResizableDialog';
//import Header from "components/Header/Header.jsx";
//import HeaderLinks from "components/Header/HeaderLinks.jsx";
//import logo from "assets/img/framelevellogo65d4s1.png"; 
      const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
        const handleClose = () => {
        setOpen(false);
      };
    
  function Checkout() {   // this is the latest running version
      const classes = useStyles();
      const [activeStep, setActiveStep] = React.useState(0);
      const handleNext = () => { setActiveStep(activeStep + 1); };
      const handleBack = () => { setActiveStep(activeStep - 1); };

       <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Recent
                </a>
              </div>
            </CardFooter>

      <React.Fragment>
              Some inside content
          <DraggableDialog openPopup={openPopup}  setOpenPopup={setOpenPopup} 
            classes={{ paper : classes.dialogPaper}}
          >
              <Checkout />
          </DraggableDialog>

      </React.Fragment>
      <CssBaseline />

      <DraggableResizableDialog />
      <CssBaseline /> 

    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <Header className={classes.header}
            height='10'
            color='transparent'
            routes={dashboardRoutes}
            brand=""
            leftLinks={<img src={logo} alt={logo} />}
            rightLinks={<HeaderLinks />}
            changeColorOnScroll={{
              height: 100,
              color: "white"
            }}
          />  
        </div>
        <main >
          <Paper >
            <Typography component="h6" variant="h6" align="left">
              Library Manager : Physical Media 
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order confirmation, and will
                    send you an update when your order has shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    ); 
 
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Title
            </DialogTitle>
            <DialogContent>
              <DialogContentText>lorem ipsum</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                ok
              </Button>
            </DialogActions>
          </Dialog>

  function Checkout() {   // this is the latest running version
    // const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleNext = () => {
      setActiveStep(activeStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };
  
    //function Dodialog() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false);};
  
    return (
      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open dialog (from dashboard)
        </Button>
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Title
          </DialogTitle>
          <DialogContent>
            <DialogContentText>lorem ipsum</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

  function Checkoutcurrent() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleNext = () => {
      setActiveStep(activeStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };
  
    //function Dodialog() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false);};
  
    return (

      <React.Fragment>
      <CssBaseline />

      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand=""
          leftLinks={<img src={logo} alt={logo} />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
.     </div>
      <main >
        <Typography variant="h5" gutterBottom>
            123...
            this is CHECKOUT from the DASHBOARD file.
          </Typography>
        <Copyright />
      </main>
      </React.Fragment>

    );

      <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open dialog
        </Button>
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Title
          </DialogTitle>
          <DialogContent>
            <DialogContentText>lorem ipsum</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>    
        
  function Checkoutnew() {
    // const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
  
    const handleNext = () => {
      setActiveStep(activeStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };
  
    //function Dodialog() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false);};
  
    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand=""
            leftLinks={<img src={logo} alt={logo} />}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 100,
              color: "white"
            }}
          />
  .     </div>
        <main >
          <Paper >
            <Typography component="h6" variant="h6" align="left">
              Library Manager : Physical Media 
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order confirmation, and will
                    send you an update when your order has shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    );
    } 
  */

