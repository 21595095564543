import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const itemhdr = {
  fontSize: "18px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "20px",
  marginRight: "120px",
  textAlign: "left"
};

const itembody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "0px",
  marginRight: "120px",
  textAlign: "left"
};

class ServicesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center"
              }}
            >
              We design our products to fit your business...
            </h4>
            <h5
              className={classes.description}
              style={{
                paddingTop: 0,
                marginTop: -30
              }}
            >
              Look out, new media is exploding.&nbsp;&nbsp;With 3.5 billion
              smartphones in use today, instant content is capturing
              everyone&apos;s attention.&nbsp;&nbsp;Well produced, interesting
              and timely content is understandably in demand for these
              consumers.&nbsp;&nbsp;If they don&apos;t like your offering, they
              simply filter to the next search result, moving around the web
              until they find what they&apos;re looking for.&nbsp;&nbsp;In this
              new world, suppliers have to be smart, responsive and tuned-in if
              they want to build an audience. FrameLevel knows the territory and
              we can help.&nbsp;&nbsp;We supply the software to QUANTIFY,
              MANAGE, CONSTRUCT, and DELIVER the best content from your library,
              done quickly and simply with easy-to-learn, easy-to-use tools from
              our extensive software catalog.
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Our Mission is Simple</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Drawing on years of successful software development covering a
              wide range of management and production issues, we provide
              carefully crafted software tools for today&apos;s business
              community. From specifications to design to custom development, we
              can deliver the solution you need when you&apos;re ready to start
              your next project
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2} />
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Call us if you have questions or are considering a future project
              that you would like to discuss. We always enjoy the discussion,
              and we&apos;re available to review the possibilities or come over
              to see your operation if the situation looks interesting. From
              data design to custom solutions, we can provide the skills you
              need whenever you&apos;re ready to get started.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(ServicesSection);
