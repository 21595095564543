/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "store/actions";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
//import { Apps, CloudDownload } from "@material-ui/icons";
import { Apps, CloudDownload } from "@material-ui/icons/CloudDownload";

// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown.jsx";
import Button from "../CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

const RegisterButton = (props) => (
  <ListItem className={props.classes.listItem}>
    <Link to={"/login-page"}>
      <Button
        href=""
        color="transparent"
        target="_blank"
        style={{ color: "white", }}
        className={props.classes.navLink}
      >
        Register
      </Button>
    </Link>
  </ListItem>
);
//        style={{ color: "white" }}

// the logout component emits a logout signal to redux
const Logout = connect((dispatch) => ({ dispatch }))((props) => (
  <ListItem className={props.classes.listItem}>
    <Button
      href=""
      color="transparent"
      target="_blank"
      style={{ color: "white" }}
      onClick={() => props.dispatch(logout())}
      className={props.classes.navLink}
    >
      Logout
    </Button>
  </ListItem>
));

function HeaderLinks({ ...props }) {
  const { classes } = props;
  const inlineblockContainer = {
    display: "flex", // display: "inline-block",
    flexDirection: "row",
    padding: "0px 0px",
  };

  return (
    <List className={classes.list} style={inlineblockContainer}>
      <ListItem className={classes.listItem}>
        <Link to="/products-page" className={classes.dropdownLink}>
            {/*  Products */}
          <Button
            color="transparent"
            target="_blank"
            style={{
              color: "white",
              paddingLeft: "0",
              paddingTop: "0",
              paddingRight: "0",
            }}
            className={classes.navLink}
          >
            Products
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/services-page" className={classes.dropdownLink}>
            {/*  Services */}
          <Button
            color="transparent"
            target="_blank"
            style={{
              color: "white",
              paddingLeft: "0",
              paddingTop: "0",
              paddingRight: "0",
            }}
            className={classes.navLink}
          >
            Services
          </Button>
          {/*
           */}
        </Link>
        {/*  href="#pablo" */}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/solutions-page" className={classes.dropdownLink}>
            {/*  Solutions */}
          <Button
            color="transparent"
            target="_blank"
            style={{
              color: "white",
              paddingLeft: "0",
              paddingTop: "0",
              paddingRight: "0",
            }}
            className={classes.navLink}
          >
            Solutions
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/markets-page" className={classes.dropdownLink}>
            {/*  Markets */}
          <Button
            color="transparent"
            target="_blank"
            style={{
              color: "white",
              paddingLeft: "0",
              paddingTop: "0",
              paddingRight: "0",
            }}
            className={classes.navLink}
          >
            Markets
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/aboutus-page" className={classes.dropdownLink}>
            {/*  About Us */}
          <Button
            color="transparent"
            target="_blank"
            style={{
              color: "white",
              paddingLeft: "0",
              paddingTop: "0",
              paddingRight: "0",
            }}
            className={classes.navLink}
          >
            About Us
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to={props.state.user ? "/dashboard" : "/login-page"}>
          <Button
            href=""
            color="transparent"
            target="_blank"
            style={{ color: "white", paddingLeft: "0", paddingRight: "0" }}
            className={classes.navLink}
          >
            {props.state.user
              ? `${props.state.user.name} ${props.state.user.surname}`
              : `Login`}
          </Button>
        </Link>
      </ListItem>
      {props.state.user ? <Logout {...props} /> : <RegisterButton {...props} />}
    </List>
  );
}

//         <Link to={props.state.user ? "/profile-page" : "/login-page"}>

const HeaderLinksContainer = connect((state) => ({ state }))(HeaderLinks);

export default withStyles(headerLinksStyle)(HeaderLinksContainer);
