import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
//import Subject from "@material-ui/icons/Subject";
import DoneAll from "@material-ui/icons/DoneAll";
import BuildOutlined from "@material-ui/icons/BuildOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import ClientList from "./ClientList.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

//import Autorenew from "components/Grid/Autorenew";
//import FlightTakeoff from "components/Grid/FlightTakeoff";
//import MotorcycleOutlined from "components/InfoArea/MotorcycleOutlined";

import Autorenew from "@material-ui/icons/Autorenew";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import MotorcycleOutlined from "@material-ui/icons/MotorcycleOutlined";

//import {
//  Autorenew,
//  FlightTakeoff,
//  MotorcycleOutlined
//} from "@material-ui/icons";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              We understand the important issues...
            </h4>
            <h5 className={classes.description}>
              Consider that we spent the last decade building media management
              solutions for some of the world’s largest asset
              libraries.&nbsp;&nbsp;Along the way we learned how to eliminate
              the unnecessary expense and technical complication.&nbsp;&nbsp;Now
              we've engineered a set of highly useful tools and processes that
              can be tailored to fit any business model regardless of industry
              or market vertical, that can be shaped to match any client's
              unique requirements.
            </h5>
          </GridItem>
        </GridContainer>
        <ClientList />
        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "normal",
            textAlign: "center",
          }}
        >
          and we can help you to...
        </h4>
        <GridContainer justify="flex-start">{/**/}      
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="get organized"
              description="store your media wherever you want, manage it from a single source"
              icon={DoneAll}
              iconColor="info"
              horizontal
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="control everything"
              description="manage your entire collection – digital, physical and archival - from one place"
              icon={DoneAll}
              iconColor="success"
              horizontal
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="rev production"
              description="quickly find, select and utilize your assets to leverage your collection and produce your best results"
              icon={BuildOutlined}
              iconColor="danger"
              horizontal
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="simplify storage"
              description="improve access, save big money on storage, simplify the process and enjoy the rewards"
              icon={Autorenew}
              iconColor="info"
              horizontal
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="sync your favorite tools"
              description="happy with your current DAM or archive software - we'll integrate our platform to give you the best of both worlds"
              icon={MotorcycleOutlined}
              iconColor="success"
              horizontal
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title="expand your horizons"
              description="add great business tools like Monday and Slack - project management, scheduling, instant messaging and all sorts of hot features to boost your bottom line"
              icon={FlightTakeoff}
              iconColor="danger"
              horizontal
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
