import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DoneAll from "@material-ui/icons/DoneAll";
import FlightTakeoff from "@material-ui/icons/FlightTakeoff";
import Autorenew from "@material-ui/icons/Autorenew";
import MotorcycleOutlined from "@material-ui/icons/MotorcycleOutlined";
//import { Autorenew, MotorcycleOutlined } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class ProStorSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              We design our products to fit your business...
            </h4>
            <h5
              className={classes.description}
              style={{
                paddingTop: 0,
                marginTop: -30,
              }}
            >
              In order to leverage the return from content development you need
              to effectively manage and carefully track your entire
              collection.&nbsp;&nbsp;We&apos;ll organize your library, tagging
              and identifying your material in ways that make it easy to use,
              housed in a well designed, responsive data store that addresses
              your needs and grows with your business.&nbsp;&nbsp;Better yet,
              we&apos;ll provide extensive, fully integrated archival services
              that protect and index every item, stack it effectively, and make
              it all available using our friendly library
              services.&nbsp;&nbsp;Finally, to close the deal, we&apos;ll
              deliver a solution that fully integrates your physical, digital,
              and archival catalogs so that you can search, use and profit from
              your entire library from a single source, no matter where
              it&apos;s stored or what format it&apos;s in.
            </h5>
          </GridItem>
        </GridContainer>

        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "bold",
            marginLeft: "40px",
            textAlign: "left",
          }}
        >
          PROSTOR - CONTENT LIBRARY MANAGEMENT
        </h4>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            <DoneAll
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px",
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left",
              }}
            >
              Library Services
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left",
              }}
            >
              Well engineered library services form the working backbone of an
              effective content library system. Users need to know what material
              is in the library, when it will be available, and how to get it.
              ProStor is our easy-to-use, industry tested library services
              platform that gives them all of the services they expect from a
              well provisioned library management toolset.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Autorenew
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px",
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left",
              }}
            >
              Physical Media Management
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left",
              }}
            >
              We revisit all of your valuable old-school physical assets,
              organizing them into a well designed, integrated and responsive
              physical library collection that showcases their true value. When
              we&apos;re done, your material is tagged and identified in ways
              that make it easy to find and easy to use, helping you run your
              operation effectively while keeping your business interests in
              focus.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <MotorcycleOutlined
              color="secondary"
              style={{
                fontSize: "60",
                marginLeft: "120px",
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left",
              }}
            >
              Digital Media Management
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left",
              }}
            >
              We make it easy to manage digital content, either individually, in
              groups with recurring detail, or as elements linked from your
              existing DAM production software. Your digital library collection
              is well designed, integrated and responsive, showcasing your
              assets&apos; true value, linked and ready to catalog, annotate and
              tag as you see fit.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <FlightTakeoff
              color="action"
              style={{
                fontSize: "60",
                marginLeft: "120px",
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left",
              }}
            >
              Archive Creation & Control
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left",
              }}
            >
              We offer tools for both periodic backups and archival services in
              our standard configuration. We let you slice and dice the
              backup/archival process in any number of different ways with drag
              and drop, stored procedures, and all of the usual methods for
              deciding what gets copied where and when. We support most devices,
              preferring the latest LTO and cloud offerings, but in fact
              servicing almost any standard device you choose - making your
              older removable drives, thumbs and tape systems good candidates
              for the job. Better yet, all of that backup media gets identified,
              tagged, and slotted into your physical media collection. Along the
              way, every file you transfer gets cataloged and completely
              identified within it&apos;s transaction set. When we&apos;re done,
              every transfer item is searchable, individually identifiable, and
              can be requested and recalled quickly using our library services.
              As a result, your entire archive becomes viable and accessable
              using our standard search and retrieve tools. In our experience,
              we prefer stacking your data in well organized LTO configurations,
              where we can deliver large multi-petabyte stacks that will fit on
              a long wall in a back office. If you add in our off-site and cloud
              services you have a very compact, powerful, fully indexed, easily
              accessable archival solution that works along side your physical
              and digital libraries, saves you tons of money and remains
              completely under your control.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <DoneAll
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px",
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                textAlign: "left",
              }}
            >
              Annotation, Cataloging, Content Identification and Tagging
              Services
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left",
              }}
            >
              Content annotation and tagging can be a valuable tool for a wide
              array of business types that process and market media products. We
              provide a wide array of cataloging and tagging tools that mark and
              recall your media from top to bottom with effective, searchable,
              customer defined boundaries and effective, saveable recall rules
              that narrow searches and let users focus on the most important
              material quickly.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(ProStorSection);
