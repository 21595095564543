//import React from "react";
import React, { useState } from 'react';//import { send } from 'emailjs-com';
import emailjs from '@emailjs/browser';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import logo from "assets/img/framelevellogo65d4s1.png"; // relative path to image
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import AboutUsSection from "./Sections/AboutUsSection.jsx";
//import ContactUs from "./Sections/ContactUs_sav.jsx";
import ContactUs from "views/LandingPage/Sections/ContactUs";

import Paper, { PaperProps } from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Draggable from 'react-draggable';
import { ResizableBox } from "react-resizable";

const dashboardRoutes = [];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        framelevel
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function AboutUsPage(props) {
    const { classes, ...rest } = { ...props };
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand=""
          leftLinks={<img src={logo} alt={logo} />}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/mway_trimmed_fromesa.jpg")} >    {/** image={require("assets/img/11eb7487-70c9-b6d9-f66e-c58996de6cd8-web.jpg") */}
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3 className={classes.title}>About Us</h3>
                <h4>Learn About Who We Are</h4>
                <br />
              </GridItem>
              <br />
              <Button 
                color="danger"
                size="lg"
                
                rel="noopener noreferrer" 
                onClick={handleClickOpen} >
                Learn More
              </Button>                  
            </GridContainer>
          </div>
          {/*target="_blank"* */}
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <AboutUsSection />
            <ContactUs />
          </div>
        </div>
        <Footer />
      </div>
    );

    function PaperComponent(props) {
      return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      );
    }
    
    function DraggableDialog() {
      const [activeStep, setActiveStep] = React.useState(0);

{/**  const handleNext = () => {
        setActiveStep(activeStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep(activeStep - 1);      };*/}
 
      const [toSend, setToSend] = useState({
        from_name: '',
        from_email: '',
        to_name: 'framelevel_support',
        message: '',
        to_email: 'info@framelevel.com',
      });
    
      const handleSend = (e) => {
        e.preventDefault();
        emailjs.send(
          'service_b0ntyq6',
          'template_7dsk36e',
          toSend,
          'user_bABDJQjIfbzUvJAxyJlwr'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };
    
      return (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            maxWidth={false}
            aria-labelledby="draggable-dialog-title"
          >
            <ResizableBox
              height={680}
              width={920}
              className={classes.resizable}    //  className={this.props.classes.resizable}
            >
                      {/**leftLinks={<img src={logo} alt={logo} />} */}
              <main >
                <Paper className={classes.paper}>
                  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Header className={classes.header}
                      color="transparent"
                      routes={dashboardRoutes}
                      brand=""
                      rightLinks={<HeaderLinks />}
                      fixed
                      changeColorOnScroll={{
                        height: 100,
                        color: "white"
                      }}
                    />

                      <div className={classes.section}>
                        <br/><br/>
                        <GridContainer justify="center">
                          <GridItem cs={12} sm={12} md={8}>
                            <h4
                              className={classes.title}
                              style={{
                                paddingTop: 0,
                                fontSize: "16px",
                                color: "purple",
                                fontStyle: "italic",
                                fontWeight: "normal",
                                textAlign: "center"
                              }}
                            >
                              Contact us...
                            </h4>
                            <h5 className={classes.description}>
                              If you have questions, need specific information or want some
                              help, send us a note that describes your situation and we'll
                              respond as quickly as possible.
                            </h5>
                            <form>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
			                	          <CustomInput
			                	            labelText="Your Name"
			                	            id="from_name"
			                	            name="from_name"
                                    onChange={handleChange}
			                	            formControlProps={{
			                	          	fullWidth: true
			                	            }}
                                    />
			                          </GridItem>
			                          <GridItem xs={12} sm={12} md={6}>
			                	          <CustomInput
			                	            labelText="Your Email"
			                	            id="from_email"
			                	            name="from_email"
                                    onChange={handleChange}
			                	            formControlProps={{
			                	          	fullWidth: true
			                	            }}
                                    />
			                          </GridItem>
                                <CustomInput
                                  labelText="Your Message"
                                  id="message"
                                  name="message"
                                  onChange={handleChange}
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.textArea
                                  }}
                                  inputProps={{
                                    multiline: true,
                                    rows: 5
                                  }}
                                  />
                                <GridContainer justify="center">
                                  <GridItem
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    className={classes.textCenter}
                                  >
                                    <Button
                                      color="danger"
                                      size="lg"
                                      target="_blank"
                                      rel="noopener noreferrer" 
                                      variant="contained"
                                      onClick={handleSend} >
                                      connect...
                                    </Button>
                                  </GridItem>
                                </GridContainer>
                              </GridContainer>
                              <br/><br />
                            </form>
                          </GridItem>
                        </GridContainer>
                        <div id="bot"></div>
                      </div>

                    </DialogTitle>
                  </Paper>
                  <Copyright />
                </main>
              </ResizableBox>
            </Dialog>
        </div>
      );
    }    
  
              {/**
                            <Button color="primary">Send Message</Button>
               * <Typography component="h6" variant="h6" align="left">
                Library Manager : Physical Media 
              </Typograph

              <Button 
                color="danger"
                size="lg"
                target="_blank"
                rel="noopener noreferrer" 
                component={Link} to="contactus-page">
                Learn More
              </Button>                  
                component={Link} to="views/LandingPage/Sections/FeatureList">
              <Button
                color="danger"
                size="lg"
                href='AboutUs'
                target="_blank"
                rel="noopener noreferrer"
              >Learn More                
              </Button>

              import { Link } from 'react-router-dom'
              import Button from '@material-ui/core/Button';
                                
              <Button component={Link} to="/open-collective">
                Link
              </Button>                  

                  href={"ContactUs"} 
                  onClick="views/LandingPage/Sections/ContactUs.jsx"
                  onClick="location.href='./sections/contactus.jsx';"
                  href="#bot"   href="https://appseed.us/fullstack-apps-generator"  ref={"./sections/contactus"}     <i className="fas fa-rocket" />  
                  <i className="fas fa-rocket" /> Learn More
              */}
  //}
};

export default withStyles(landingPageStyle)(AboutUsPage);
