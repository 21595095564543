import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Done from "@material-ui/icons/Done";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

const itemicon = {
  color: "primary",
  fontSize: "20",
  marginLeft: "194px"
};

const itemhdr = {
  fontSize: "18px",
  color: "purple",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "20px",
  marginRight: "120px",
  textAlign: "left"
};

const itembody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

const itemlinebody = {
  fontSize: "15px",
  color: "#000000",
  fontStyle: "normal",
  marginLeft: "60px",
  marginRight: "140px",
  textAlign: "left"
};

class ServicesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h4
              className={classes.title}
              style={{
                paddingTop: 0,
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                textAlign: "center"
              }}
            >
              Solutions designed to tackle complex problems and succeed...
            </h4>
            <h5
              className={classes.description}
              style={{
                paddingTop: 0,
                marginTop: -30
              }}
            >
              If your operations are too slow or your archive is barely existant
              and you want a better solution, it usually requires careful review
              and extensive planning to get from where you are to where you want
              to be. Maybe your operational software is constraining workflow
              resulting in deliverables that don&apos;t fit your needs. At
              FrameLevel we leverage our experience to fit your business and
              craft applications that increase productivity in ways that work.
              We evaluate your situation and prescribe solutions that take your
              operation from where it is today into a better future. Here are
              some of cases that we&apos;ve encountered, along with brief
              descriptions of ways to turn that situation to your advantage.
            </h5>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            {/* <Done color="primary" style={itemicon} /> */}
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Start From Scratch</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              If your media collection is sitting somewhere in boxes,
              uncataloged and unorganized, we can correct the
              problem.&nbsp;&nbsp;We&apos;ll send in expert librarians to
              organize, document, catalog, package and barcode each piece of
              media,place it in your storage vault, note it&apos;s location, and
              then insert all that information into your brand new
              database.&nbsp;&nbsp;If you have both physical and digital media,
              we can share all of the metadata in one database so you know where
              everything is and can start managing your library from day one.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2}>
            {/* <Done color="primary" style={itemicon} /> */}
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Digitize your Existing Physical Library</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              If you own a valuable library of legacy material but have some or
              all of your content stored on physical media, I probably
              don&apos;t have to tell you that you have a long term
              problem.&nbsp;&nbsp; That media will degrade over time, so you
              need to address the issue immediately.&nbsp;&nbsp;We can solve
              your problem.&nbsp;&nbsp; We&apos;ll digitize the entire catalog,
              put it into a modern in-house digital library, index everything,
              provide all the management services you need, and move the legacy
              media to deep storage where the costs are reasonable.&nbsp;&nbsp;
              Contact us and we&apos;ll outline the process to get started.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2}>
            {/* <Done color="primary" style={itemicon} /> */}
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Replace Your Old Database</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Do you have an existing asset management setup that&apos;s
              somewhat useful but outdated? Want to switch to a solid library
              structure for all of your media wherever it is and whatever format
              it&apos;s in? We can help. We&apos;ll move the data so that you
              don&apos;t loose anything, and while we&apos;re at it we&apos;ll
              review what you have and add whatever&apos;s missing, including -
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have labeled physical content in your old database?
              We&apos;ll add matching records to the new database, move and
              review metadata as we go, relabel and repackage as needed,
              reorganize your vault if it&apos;s called for, and provide a fully
              functional working library on day one.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have labeled physical content in your old database?
              We&apos;ll add matching records to the new database, move and
              review metadata as we go, relabel and repackage as needed,
              reorganize your vault if it&apos;s called for, and provide a fully
              functional working library on day one.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have uncataloged physical content? We&apos;ll insert new
              records, add new barcodes and metadata as we go, making sure that
              the content matches your specs.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have digital content in active libraries or DAM systems?
              We&apos;ll migrate the metadata and track the content using our
              library tools, recording usage, backups and movement, making it
              all available to users. Even uncataloged digital files are always
              available for search, reserve and recall.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have uncataloged digital content you&apos;d like to add? We
              allow you to add content at your descretion. No need to add
              everything, we&apos;ll track what you want and make it available
              to all of the library functions, including checkout and reserve.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemlinebody}>
              Do you have archived content that you&apos;d like to include? In
              most cases we can access the archive datasets, pull metadata to
              power our cross reference tools, add the reference points to our
              data repository for each file in the archive, then atalog the
              physical elements and add them to the physical inventory so that
              you know where everything lives. We&apos;ll go through everything
              you have on archive and backup, add the info as we go, and leave
              you with a powerful view into all those files that have been
              sitting on backup for all this time.
            </p>
          </GridItem>

          <GridItem cs={2} sm={2} md={2}></GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Add A New Collection</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              If you&apos;re already using our data store, and you have
              additional collections you&apos;d like to add, we can ingest the
              information, review the content, retain datakeys for cross
              reference, and add pertinent metadata during the process. All the
              data from the new dataset will become searchable and available
              using our standard library commands, ready for comparison, recall,
              and tracking just like any other internal library element. We can
              often supply structured procedures to handle the transition
              automatically, just select the transfer type, point us to the
              dataset and go.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}></GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Connect Editing & Production Resources</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              If you have hard working editing tools and production apps that
              you like, chances are we can extract the metadata you need, use
              native keys and cross-reference the information into your library
              data store. That&apos;s a good thing, because once the information
              is linked, you can search to your hearts content, make all the
              right associations, and pull exactly the best content from across
              the entire library for use in your next hot project.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}></GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>SuperCharge Your Workflow</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              After you&apos;ve made the transition and your library is up,
              running, and improving your production process every day, we can
              take a look at your additional production related issues. We have
              a host of exciting applications that we can introduce into your
              environment and link to, from scheduling and project management to
              collaboration to distribution. Careful planning can lead to
              effective use of the right products at a very reasonable cost,
              netting your business with increased productivity and a healthy
              bottom line.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}></GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itemhdr}>Extend the Enterprise</p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Done color="primary" style={itemicon} />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p style={itembody}>
              Once the library is in place and production workflow is humming
              along, we can extend the business model to include a number of
              advantages like effective cloud utilization, dirext producer
              links, and a whole range of other options that we&apos;d be happy
              to discuss with you at the right time.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(ServicesSection);
