import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DoneAll from "@material-ui/icons/DoneAll";
import Autorenew from "@material-ui/icons/Autorenew";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class EZCatSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "bold",
            marginLeft: "40px",
            textAlign: "left"
          }}
        >
          EZCAT - CONTENT CATALOGING, ANNOTATION AND TAGGING
        </h4>
        <p
          style={{
            fontSize: "16px",
            color: "#000000",
            fontStyle: "bold",
            marginLeft: "80px",
            marginRight: "80px",
            textAlign: "left"
          }}
        >
          Effective content control is the key to bringing your media collection
          to life. We'll organize your library, and when we're done, your
          material will be tagged and identified in ways that make it easy to
          find – housed in a well designed, integrated and responsive database
          that addresses your needs and grows with your business.
          <br />
        </p>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            <DoneAll
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px"
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left"
              }}
            >
              Library Services
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left"
              }}
            >
              Well engineered library services form the backbone of of any
              effective content management system. Users need to know what
              material is available and how to get it. We do both with BackPac,
              our industry tested, easy-to-use library services platform.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Autorenew
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px"
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left"
              }}
            >
              Cataloging, Annotation and Content Tagging
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left"
              }}
            >
              Content annotation and tagging can be a valuable tool for a wide
              array of business types that process and market media products. We
              provide a wide array of cataloging and tagging tools that mark and
              recall your media from top to bottom with effective, searchable,
              customer defined boundaries and effective, saveable recall rules
              that narrow searches and let users focus on the most important
              material quickly.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(EZCatSection);
