import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DoneAll from "@material-ui/icons/DoneAll";
import Autorenew from "@material-ui/icons/Autorenew";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class BackPacSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4
          className={classes.title}
          style={{
            paddingTop: 0,
            fontSize: "16px",
            color: "purple",
            fontStyle: "italic",
            fontWeight: "bold",
            marginLeft: "40px",
            textAlign: "left"
          }}
        >
          BACKPAC - ARCHIVE SERVICES
        </h4>
        <p
          style={{
            fontSize: "16px",
            color: "#000000",
            fontStyle: "bold",
            marginLeft: "80px",
            marginRight: "80px",
            textAlign: "left"
          }}
        >
          Effective content control is the key to bringing your media collection
          to life. We'll organize your library, and when we're done, your
          material will be tagged and identified in ways that make it easy to
          find – housed in a well designed, integrated and responsive database
          that addresses your needs and grows with your business.
        </p>
        <GridContainer justify="center">
          <GridItem cs={2} sm={2} md={2}>
            <DoneAll
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px"
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left"
              }}
            >
              Library Services
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left"
              }}
            >
              Reliable and accessable archival services are an essential feature
              for managing and extending the influence of your media library.
              Users rightfully insist on knowing what material is in the
              library, when it will be available, and how to retrieve it.
              BackPac, our easy-to-use, effective archival services platform,
              supplies the tools users need to archive, control and access all
              of t he assets contained in the library data store.
            </p>
          </GridItem>
          <GridItem cs={2} sm={2} md={2}>
            <Autorenew
              color="primary"
              style={{
                fontSize: "60",
                marginLeft: "120px"
              }}
            />
          </GridItem>
          <GridItem cs={10} sm={10} md={10}>
            <p
              style={{
                fontSize: "16px",
                color: "purple",
                fontStyle: "italic",
                fontWeight: "normal",
                marginLeft: "20px",
                marginRight: "120px",
                textAlign: "left"
              }}
            >
              Archive Creation & Management
            </p>
            <p
              style={{
                fontSize: "16px",
                color: "#000000",
                fontStyle: "bold",
                marginLeft: "40px",
                marginRight: "140px",
                textAlign: "left"
              }}
            >
              With BackPac we offer expert tools for both periodic backups and
              archival services. We let you slice and dice the backup/archival
              process in any number of different ways with drag and drop, stored
              procedures, and all of the usual methods for deciding what gets
              copied where and when. We support most devices, preferring the
              latest LTO and cloud offerings, but in fact servicing almost any
              standard device you choose - making your older removable drives,
              thumbs and tape systems good candidates for the job. Better yet,
              all of that backup media gets identified, tagged, and slotted into
              your physical media collection. Along the way, every file you
              transfer gets cataloged and completely identified within it's
              transaction set. When we're done, every transfer item is
              searchable, individually identifiable, and can be requested and
              recalled quickly using our library services. As a result, your
              entire archive becomes viable and accessable using our standard
              search and retrieve tools. In our experience, we prefer stacking
              your data in well organized LTO configurations, where we can
              deliver large multi-petabyte stacks that will fit on a long wall
              in a back office. If you add in our off-site and cloud services
              you have a very compact, powerful, fully indexed, easily
              accessable archival solution that works along side your physical
              and digital libraries, saves you tons of money and remains
              completely under your control.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(BackPacSection);
